<footer class="footer" fxLayout="row" fxLayoutAlign="space-around center" >
    <div id="floater" class="floater floater-width" fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between center"
        inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="action($event)">
        <div class="floater-content" fxLayout="column">
            <div class="title mb-10">
                <!-- Get Started Today! -->
                <!-- Contact us -->
                Have an idea?
            </div>
            <div class="description mb-30">
                <!-- Download the Awsm app and set your kids for financial success! -->
                <!-- We would love to hear from you! -->
                Feel free and contact us
            </div>

            <!-- <div>
                <div *ngIf="downloadButtonService.isiOS || downloadButtonService.isNotAndroidNotiOS" class="store-btns app-store" (click)="goToStore();  "></div>
                <div *ngIf="downloadButtonService.isAndroid || downloadButtonService.isNotAndroidNotiOS" class="store-btns play-store" (click)="goToStore(); "></div>
                <div class="clearfix"></div>
            </div> -->
            <button
            [routerLink]="'contact-us'"
            mat-button
            class="contact-us-button"
          >
            Contact us
          </button>
            <span *ngIf="apiStatus === 'success'" class="Success"> You've joined the waitlist </span>
            <span *ngIf="apiStatus === 'failure'" class="Failure"> Something went wrong.</span>
        </div>
        <img class="floater-image" [src]="'assets/images/Contact-us-footer.svg'">
    </div>

    <div class="footer-content" fxFlex="70" fxFlex.lt-sm="88" fxLayout="column">

        <div class="footer-container mb-40" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
            <div fxLayout="column">
                <img class="footer-logo mb-10" [src]="'assets/icons/logo-footer.png'" [routerLink]="['']" routerLinkActive="router-link-active">
                <div class="footer-email">
                    <a href="mailto:info@incodus.in" target="_blank"> <u>info@incodus.in</u> </a>
                </div>
            </div>

            <div class="link-container" fxFlex="70" fxLayoutAlign="space-between">

                <div fxLayout="row" fxLayout.lt-sm="column" fxFlex="60" fxLayoutAlign="space-between">
                    <div fxLayout="column">
                        <a class="footer-link mb-15" [routerLink]="['why-us']">
                            WHY US
                        </a>
                        <a class="footer-link mb-15" [routerLink]="['how-it-works']">
                            HOW IT WORKS
                        </a>
                        <a class="footer-link mb-15" [routerLink]="['faq']">
                            FAQ
                        </a>
                    </div>

                    <div fxLayout="column">
                        <a class="footer-link mb-15" [routerLink]="['terms-policies']">
                            TERMS AND POLICIES
                        </a>
                        <a class="footer-link mb-15" [routerLink]="['privacy-policy']">
                            PRIVACY POLICY
                        </a>
                    </div>
                </div>


                <!-- <div fLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around">                                        
                    <a href="" target="_blank"><img class="footer-social-icon ml-15" [src]="'assets/images/9-footer-facebook.svg'" /></a>
                    <a href="" target="_blank"><img class="footer-social-icon ml-15" [src]="'assets/images/9-footer-instagram.svg'" /></a>
                </div>    -->

            </div>
        </div>

        <hr class="footer-separation mb-30">

        <div class="footer-description">
            © Incodus Technologies Private Limited
        </div>
    </div>
</footer>
<!-- 
<footer *ngIf="screenWidth<600">

</footer> -->
