import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardholder-agreement',
  templateUrl: './cardholder-agreement.component.html',
  styleUrls: ['./cardholder-agreement.component.scss']
})
export class CardholderAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
