<header class="header" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="screenWidth>=600" (scroll)="onScroll()"
    [ngClass]="{ 'header-shadow': pageScrolled }">
    <div class="container" fxFlex="85" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header-logo" fxLayoutAlign="flex-start center" [routerLink]="['']">
            <img class="main-logo" [src]="'assets/icons/logo.png'">
        </div>
        <div class="menu-container" fxLayout="row">
            <a class="menu-item" routerLinkActive="active-link" [routerLink]="['why-us']">
                WHY US?
            </a>
            <a class="menu-item" routerLinkActive="active-link" [routerLink]="['how-it-works']">
                HOW IT WORKS
            </a>
            <a class="menu-item" routerLinkActive="active-link" [routerLink]="['faq']">
                FAQ
            </a>
            <a class="menu-item" routerLinkActive="active-link" [routerLink]="['contact-us']">
                CONTACT US
            </a>
            <!-- <div>
                <div *ngIf="downloadButtonService.isiOS || downloadButtonService.isNotAndroidNotiOS" class="store-btns app-store" (click)="goToStore(); "></div>
                <div *ngIf="downloadButtonService.isAndroid || downloadButtonService.isNotAndroidNotiOS" class="store-btns play-store" (click)="goToStore(); "></div>
            </div> -->
            <div class="clearfix"></div>
        </div>
    </div>

    <div class="mobile-menu" fxLayout="column">
        <div fxLayout="column" id="mySidenav" class="sidenav">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="header-logo" fxLayoutAlign="flex-start center" [routerLink]="['/']" (click)="closeNav()">
                    <!-- <img class="main-logo" [src]="'assets/images/main-logo.png'"> -->
                </div>
                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
            </div>

            <div class="menu-container-mobile">
                <a class="menu-item" routerLinkActive="active-link" [routerLink]="['why-us']" (click)="closeNav()">
                    WHY US?
                </a>

                <a class="menu-item" routerLinkActive="active-link" [routerLink]="['how-it-works']" (click)="closeNav()">
                    HOW IT WORKS
                </a>
                <a class="menu-item" routerLinkActive="active-link" [routerLink]="['faq']" (click)="closeNav()">
                    FAQ
                </a>
                <a class="menu-item" routerLinkActive="active-link" [routerLink]="['contact-us']" (click)="closeNav()">
                    CONTACT US
                </a>
            </div>
        </div>
        <!-- <div id="joinButtonMobile" [ngClass]="joinButtonVisible ? 'hide' : 'show'" class="join-button-mobile" (click)="navigateToID('floater')">
             <button class="join-button">Join the waitlist</button>
            <div *ngIf="downloadButtonService.isiOS || downloadButtonService.isNotAndroidNotiOS" class="store-btns app-store header" (click)="goToStore(); "></div>
            <div *ngIf="downloadButtonService.isAndroid || downloadButtonService.isNotAndroidNotiOS" class="store-btns play-store header" (click)="goToStore(); "></div>
        </div> -->
        <div class="hamburger-container" (click)="openNav()">
            <span class="hamburger">&#9776;</span>
        </div>
    </div>
</header>


<div class="mobile-container" *ngIf="screenWidth<600" (scroll)="onScroll()" >
    <div class="header" [ngClass]="{ 'header-shadow': pageScrolled }" fxLayout="row" fxLayoutAlign="space-between center">
        <img class="main-logo" [src]="'assets/icons/logo.png'" [routerLink]="['']" [routerLinkActiveOptions]="{exact: true}">

        <div class="menu-container menu-container-for-mobile-site">
            <mat-icon (click)="toggleNav()">
            {{ sidenavOpened ? 'close' : 'menu'}}
            </mat-icon>
        </div>
    </div>

    <div id="mySidenav" class="sidenavForMobile" fxLayout="column">
        <div class="menu-container menu-container-for-mobile-site">
            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['']" [routerLinkActiveOptions]="{exact: true}">
                Home
            </a>

            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['why-us']">
                WHY US?
            </a>

            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['how-it-works']">
                HOW IT WORKS
            </a>

            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['faq']">
                FAQ
            </a>

            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['contact-us']">
                CONTACT US
            </a>

            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['terms-policies']">
                Terms and Policies
            </a>

            <a class="menu-item" routerLinkActive="active-link" (click)="closeNavForMobile()" [routerLink]="['privacy-policy']">
                Privacy Policy
            </a>
        </div>

        <div class="menu-footer" fxLayout="column" fxLayoutAlign="space-around center">
            <div class="social">
                <a class="no-decoration" href="" target="_blank">
                    <img class="insta" [src]="'assets/images/9-footer-instagram.svg'">
                </a>
                <a class="no-decoration" href="" target="_blank">
                    <img [src]="'assets/images/9-footer-facebook.svg'">
                </a>
            </div>

            <a class="no-decoration support" href="mailto:info@incodus.in">info@incodus.in</a>
        </div>
    </div>
</div>