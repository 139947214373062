<main fxLayout="column" fxLayoutAlign="center space-around" class="agreement">
    <h1>Agreement Governing the Use of AWSM Mastercard in Digital Wallets</h1>
    <section class="section-separator">
        <p>
            This Agreement Governing the Use of AWSM Mastercard issued by Evolve Bank & Trust (the “Bank”) under which AWSM Brand Management Inc. (“AWSM”) is the program manager (the “Card”) in Digital Wallets (“Agreement”) forms a legal agreement between the primary cardholder on behalf of himself/herself and on behalf of each and every authorized user of the eligible Card (“you” and “your”), the Bank and its affiliates, service providers and agents and AWSM (collectively, “we,” “us,” “our” and governs your access to and use of your Card through Apple Pay, Google Pay or Samsung Pay (each a “Digital Wallet”) on compatible mobile devices, as determined by the Digital Wallet Provider (“Supported Devices”).
        </p>
        
        <p> 
            You understand and agree that your use of a Digital Wallet is subject to the terms and conditions set forth by the third party Digital Wallet provider or another third party supporting the Digital Wallet or Digital Wallet provider (collectively, the “Digital Wallet Provider”) with respect to the use of that Digital Wallet.
        </p>
        <p>
            This Agreement does not modify the agreement governing the Card (the “Cardholder Agreement”) and you will continue to be subject to the Cardholder Agreements. The Cardholder Agreement is incorporated by reference as part of this Agreement. In the event of a conflict between this Agreement and the Cardholder Agreement with respect to a Digital Wallet or use of the Card via a Digital Wallet, this Agreement controls.
        </p>
        <p>
            By using a Card in a Digital Wallet, you agree to this Agreement, and you agree your use of your Card in connection with a Digital Wallet will comply with this Agreement. We reserve the right, in our sole discretion, to change, modify, add or remove portions of this Agreement at any time. It is your responsibility to check this Agreement periodically for changes. Your continued use of the Card in a Digital Wallet following the posting of changes will mean that you accept and agree to the changes.
        </p>
        <p>
            PLEASE CAREFULLY REVIEW THE ARBITRATION, CLASS ACTION WAIVER AND JURY WAIVER PROVISIONS SET FORTH BELOW. BY ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
        </p>
        <p> 
            If you do not agree to this Agreement, you may not add your Card to, or use your Card in connection with, a Digital Wallet.
        </p>
    </section>

    <section class="section-separator">
        <h2>I. Eligibility</h2>
        <p>
            You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), and a U.S. resident. You represent that you are at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18) and a U.S. resident. You must have the power, authority and capacity to accept this Agreement before you or any Authorized User on your account can add or use your Card in a Digital Wallet. You further agree to, and to abide by, this Agreement. In the event you violate any of the terms or conditions in this Agreement, we may terminate your access to and use of a Digital Wallet. 
        </p>
        <p>
            A Digital Wallet is available to you for the purpose of purchasing goods and services or for other transactions where that Digital Wallet is accepted. A Digital Wallet may not be accepted at all places where your Card is accepted, and your Card in a Digital Wallet may not be eligible to be used for all the features and functionalities of that Digital Wallet. Digital Wallets can only be used with Supported Devices determined by the Digital Wallet Provider for that Digital Wallet.
        </p>
    </section>
    <section class="section-separator">
        <h2>II. Enrollment</h2>
        <p>
            Before you are able to use your Card in a Digital Wallet, you must first add your Card to a Digital Wallet. To add your Card to a Digital Wallet, you must first register your Card with that Digital Wallet by linking your Card to that Digital Wallet. By doing this, you agree to allow us to share your Card information with the Digital Wallet Provider. You will be required to complete this process for each Digital Wallet and for each Card. We may require you to take additional steps to authenticate yourself before your Card is added to a Digital Wallet. 
        </p>
        <p>
            You agree that the digital version of your Card in a Digital Wallet may, in our sole discretion, be automatically updated or upgraded without notice to you. 
        </p>
        <p>
            If your physical plastic Card is lost or stolen and your Supported Device is not lost or stolen, you may be required to add the new physical plastic Card to a Digital Wallet. If your Supported Device is lost or stolen, you will need to add your Card to a Digital Wallet on a new Supported Device. We do not recommend, endorse or make any representation or warranty of any kind regarding the performance or operation of your Supported Device or the Digital Wallet. You are responsible for the Supported Device, including its selection, and for all issues relating to the operation, performance and costs associated with such Supported Device. You authorize your wireless operator and/or us to disclose your mobile number, name, address, email, network status, customer type, customer role, billing type, account activation date and device make and model, where provided, in accordance with your mobile operator’s or our privacy policy, to allow verification of your identity and for fraud prevention purposes.
        </p>
    </section>
    
    <section class="section-separator">
        <h2>III. Using Your Card with a Digital Wallet</h2>
        <p>
            Once you add a Card to a Digital Wallet on a Supported Device, you may use the Supported Device to make payments utilizing the Card at any merchant that accepts the Digital Wallet and Card. You may also make contactless payments at merchants who accept the Digital Wallet and the Card and have contactless-enabled point-of-sale terminals or readers that accept contactless payment. By selecting an added Card and placing your Supported Device near a merchant’s contactless-enabled point-of-sale terminal or reader, or by using a Card for an in-app or a website purchase, you are authorizing the payment for the applicable merchant’s products or services with that Card in the Digital Wallet.
        </p>
        <p>
            The Digital Wallet may display transaction history on your Supported Device. The transaction history does not reflect any post-authorization activities (e.g., settlement, foreign currency exchange, chargebacks, etc.). The details for your transaction history in connection with that Digital Wallet may not match the actual transaction details, including the amount that ultimately clears, settles and posts to your Card account.
        </p>
        <p>
            All transactions initiated by you using the Card or Digital Wallet must comply with all federal, state and local laws, rules and regulations applicable to you, including any applicable tax laws and regulations, and you must not use your Card through a Digital Wallet for any fraudulent undertaking or in any manner so as to interfere with the operation of a Digital Wallet.
        </p>
    </section>
    <section class="section-separator">
        <h2>IV. Additional Terms of Specific Digital Wallets</h2>
        <h4>
            Google Pay:
        </h4>
        <p>
            This Agreement does not apply to transactions in Google products that are not initiated or effectuated through the Digital Wallet. Your use of Google Pay is also subject to the terms and conditions set forth in the Google Pay Terms of Service. We do not control or endorse the provisioning or use of Google Pay. 
        </p>
         
         <h4>
            Apple Pay:
         </h4>
         <p>
            Your use of Apple Pay is also subject to the terms and conditions set forth in the Apple Pay Terms of Service. We do not control or endorse the provisioning or use of Apple Pay. 
         </p>
         <h4>
             Samsung Pay:
        </h4>
        <p>
                Your use of Samsung Pay is also subject to the terms and conditions set forth in the Samsung Pay Terms and Conditions. We do not control or endorse the provisioning or use of Samsung Pay Money Transfer services.
        </p>
    </section>
    <section class="section-separator">
        <h2>V. Fees</h2>
        <p>
            We do not charge you any fees to add a Card to a Digital Wallet or to use the Card through a Digital Wallet. However, Digital Wallet providers and/or other third parties, such as your wireless carrier or data service providers, may charge you fees in connection with your use of a Digital Wallet. You agree to be solely responsible for all such fees and agree to comply with any limitations and restrictions relating to a Digital Wallet and/or your Supported Device. We reserve the right to institute or change a fee to add a Card to a Digital Wallet and/or to use a Card in connection with a Digital Wallet after sending you prior notice.
        </p>
    </section>
    <section class="section-separator">
        <h2>VI. Privacy</h2>
        <p>
            You authorize us to collect, transmit, store and use technical, location and login or other information about you and your use of the Cards through a Digital Wallet. You understand and agree that a Digital Wallet Provider and each payment network branded on your Card (including their agents, representatives, affiliates and service providers) will have access to certain details of your transactions made with merchants via use of your Cards through the Digital Wallet. We will use, share and protect your personal information in accordance with our privacy policy, as it may be amended from time to time, and that is available at awsmbank.com/privacy.html. You acknowledge that the use and disclosure of any personal information provided by you directly to a Digital Wallet provider, the applicable payment network branded on your Card or other third parties supporting that Digital Wallet, will be governed by such party’s privacy policy and not our privacy policy, and, to the greatest extent permitted by law, we are not liable for their use of your personal information. 
        </p>
        <p>
            We periodically collect and use technical data and related information (for example, we may collect technical information about your Supported Device to facilitate the updates to our services).
        </p>
        <p>
             Push notifications can be displayed on a locked or inactive device screen. Since it is possible to view this information even when your Supported Device is locked, we encourage you must maintain physical control over your Supported Device and/or turn off push notifications.
        </p>
    </section>
    <section class="section-separator">
        <h2>VII. Security</h2>
        <p>
            You must take reasonable steps to prevent the unauthorized use of your Card, Supported Device and/or Digital Wallet, including any user ID and password. If another person’s biometric identifier is loaded onto a Supported Device, you must take immediate steps to ensure that the biometric identifier is removed from the Supported Device, otherwise any transaction using such Supported Device by a person who you have granted access to will not be an unauthorized transaction for the purposes of determining liability. Similarly, if you provide your user ID, PIN or password necessary to access your Supported Device, you must take immediate steps to change such user ID, PIN or password to ensure no third party has access to or knows such user ID, PIN or password. Otherwise, any transaction using such Supported Device by a person to whom you provide your user ID, PIN or password will not be an unauthorized transaction for the purposes of determining liability. If your PIN, biometric authentication or other passcode is compromised or your Card has been used through a Digital Wallet without your permission, or you have a Supported Device and it has been lost or stolen, you must notify us immediately. If you fail to notify us without delay, you may be liable for part or all of the losses in connection with any unauthorized use of your Card in connection with that Digital Wallet. You should notify us by calling the number on the back of your Card or in the app you use to manage your Card.
        </p>
    </section>
    <section class="section-separator">
        <h2>VIII. Questions Regarding the Digital Wallet and/or Supported Devices</h2>
        <p>
            Questions concerning the use of a Digital Wallet or the operation of a Supported Device equipped with the Digital Wallet should be directed to the Digital Wallet Provider. We are not responsible for (a)”providing Digital Wallet services to you; (b) any failure of the Digital Wallet or any Supported Device; (c) your inability to use, or the unavailability of, the Digital Wallet to make a payment in connection with any transaction; (d) the performance or nonperformance of the Digital Wallet Provider or any third party providing services on behalf of such Digital Wallet provider; or (e) any loss, injury or inconvenience that you sustain as a result of matters addressed subparts (a) through (d) of this Section. For the avoidance of doubt, we are not responsible for the security, accuracy, legality, appropriateness or any other aspect of the content or function of a Digital Wallet Provider’s or any third party’s products or services.
        </p>
    </section>
    <section class="section-separator">
        <h2>IX. Third-Party Agreements and Merchant Relationships</h2>
        <p>
            Each Digital Wallet provider, your wireless carrier and other third-party websites or services that support the Digital Wallet have their own terms and conditions and privacy policies (“Third-Party Agreements”), and you are subject to those Third-Party Agreements when you give them your personal information, use their services or visit their respective sites. Merchants may present to you certain discounts, rebates or other benefits in a Digital Wallet (the “Promotions”). Promotions are subject to separate terms and conditions and are subject to change at any time and without notice to you. We will not be liable for any loss or damage as a result of any interaction between you and a merchant relating to Promotions. Subject to applicable law and your Cardholder Agreement, all matters, including delivery of goods and services, returns and warranties, are solely between you and the applicable merchants. We do not endorse or warrant the merchants that are accessible through a Digital Wallet or the Promotions that they provide.
        </p>
    </section>
    <section class="section-separator">
        <h2>X. Suspension, Cancellation and Termination</h2>
        <p>
            We may terminate this Agreement at any time without notice or suspend or terminate your access to and use of any Card at any time, with or without cause, in our absolute discretion. We reserve the right for any reason to discontinue our participation in any Digital Wallet service. Except as otherwise required by applicable law, we may block, restrict, suspend or terminate your use of any Card in a Digital Wallet or your use of any Card through a Digital Wallet at any time without notice and for any reason, including if you violate this Agreement, your Cardholder Agreement or applicable law, if we suspect fraudulent activity or as a result of the cancellation or suspension of your Card account. You agree that we will not be liable to you or any third party for any block, suspension, cancellation or termination of your use of any Card. We may also modify or suspend the type or dollar amounts of transactions allowed using Cards in connection with a Digital Wallet, change a Card’s eligibility for use with a Digital Wallet, change the enrollment process and/or change the Card authentication process.
        </p>
    </section>
    <section class="section-separator">
        <h2>XI. DISCLAIMER OF WARRANTIES</h2>
        <p>
            EACH DIGITAL WALLET IS PROVIDED BY THE APPLICABLE DIGITAL WALLET PROVIDER AND OTHER THIRD PARTIES THAT SUPPORT SUCH DIGITAL WALLET PROVIDER. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE USE OF ANY CARD IN A DIGITAL WALLET IS AT YOUR SOLE RISK. YOU UNDERSTAND THAT WE NEITHER CONTROL NOR ENDORSE, AND ARE NEITHER RESPONSIBLE NOR LIABLE FOR: (A) ANY DIGITAL WALLET OR SERVICES PROVIDED BY ANY DIGITAL WALLET PROVIDER; (B) ANY ACT OR OMISSION OF A DIGITAL WALLET PROVIDER OR ANY PARTY SUPPORTING A DIGITAL WALLET OR ANY MERCHANT; (C) SUPPORTED DEVICES, INCLUDING THEIR OPERATION; OR (D) ANY ACT OR OMISSION OF ANY OTHER THIRD PARTY. 
        </p>
        <p>
            
            FURTHER, WE ARE NOT RESPONSIBLE FOR THE ACCURACY, VALIDITY, TIMELINESS, COMPLETENESS, RELIABILITY, INTEGRITY, QUALITY, LEGALITY, USEFULNESS OR SAFETY OF ANY THIRD-PARTY INFORMATION OR DIGITAL WALLET. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, WE DISCLAIM ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS OF ANY KIND, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY AND NONINFRINGEMENT OF THIRD-PARTY RIGHTS. WE, ON BEHALF OF OURSELVES AND OUR AUTHORIZED REPRESENTATIVES. WE DO NOT WARRANT AGAINST INTERFERENCE, DELAY, INTERRUPTION OR DISRUPTION WITH YOUR USE OF ANY CARD IN CONNECTION WITH A DIGITAL WALLET. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY US, ANY OF OUR AUTHORIZED REPRESENTATIVES OR ANY THIRD PARTY WILL CREATE ANY WARRANTY.
        </p> 
        <p>
            THE ACCESS, USE AND MAINTENANCE OF A CARD WITHIN A DIGITAL WALLET ALSO DEPENDS ON THE DIGITAL WALLET SERVICES AND PROVIDERS AND THE NETWORKS OF WIRELESS CARRIERS. WE WILL NOT BE LIABLE TO YOU FOR ANY CIRCUMSTANCES THAT INTERRUPT, PREVENT OR OTHERWISE AFFECT THE FUNCTIONING OF ANY CARD, INCLUDING UNAVAILABILITY OF A DIGITAL WALLET SERVICE OR WIRELESS SERVICE, NETWORK DELAYS, LIMITATIONS ON WIRELESS COVERAGE, SYSTEM OUTAGES OR INTERRUPTION OF A WIRELESS CONNECTION. WE DISCLAIM ANY RESPONSIBILITY FOR ANY WIRELESS SERVICE USED TO ACCESS, USE OR MAINTAIN A CARD WITHIN A DIGITAL WALLET
        </p>
    </section>
    <section class="section-separator">
        <h2>XII. LIMITATION OF LIABILITY</h2>
        <p>
            UNDER NO CIRCUMSTANCES, AND UNDER NO LEGAL THEORY, INCLUDING BUT NOT LIMITED TO, CONTRACT, NEGLIGENCE, TORTS OR STRICT LIABILITY, SHALL WE BE LIABLE FOR ANY GENERAL, DIRECT, SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES OR LOSSES (INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA OR USE OR COST OF COVER) ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE DIGITAL WALLET, THE CARD IN CONNECTION TO A DIGITAL WALLET OR ANY THIRD-PARTY SERVICES, ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OFFERED BY A MERCHANT OR DIGITAL WALLET PROVIDER, OR ANY SUPPORTED DEVICES OR WIRELESS NETWORKS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY OF THE FOREGOING DAMAGES. UNDER NO CIRCUMSTANCES WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF YOUR CARD IN A DIGITAL WALLET.
        </p>
        <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (A) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF A DIGITAL WALLET; (B) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (C) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM A DIGITAL WALLET; (D) ANY BUGS, VIRUSES, TROJAN HORSES OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH A DIGITAL WALLET BY ANY THIRD PARTY; AND/OR (E) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT MADE AVAILABLE THROUGH A DIGITAL WALLET OR OTHERWISE MADE AVAILABLE BY US. 
        </p>
        <p>
            THE AGGREGATE LIABILITY OF US TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE USE OF OR ANY INABILITY TO USE ANY PORTION OF A DIGITAL WALLET (INCLUDING ACCESS OR USING YOUR CARD IN CONNECTION WITH A DIGITAL WALLET OR SUPPORTED DEVICE) OR OTHERWISE UNDER THIS AGREEMENT, or the DIGITAL WALLET OR SERVICES CONTEMPLATED HEREIN, WHETHER IN CONTRACT, TORT OR OTHERWISE, IS LIMITED TO THE LESSER OF: (1) THE AMOUNT YOU HAVE PAID TO US FOR ACCESS TO AND THE USE OF THE DIGITAL WALLET IN THE 12”MONTHS PRIOR TO THE EVENT(S) OR CIRCUMSTANCES GIVING RISE TO CLAIM OR (2)”$100. 
        </p>
        <p>
            THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
        </p>
    </section>
    <section class="section-separator">
        <h2>XIII. Indemnification</h2>
        <p>
            YOU AGREE TO INDEMNIFY AND HOLD HARMLESS US AND ITS AFFILIATES, AND EACH OF THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, PARTNERS, DONORS, CONTRACTORS, SUPPLIERS, SERVICE PROVIDERS AND SUBCONTRACTORS FROM AND AGAINST ANY AND ALL CLAIMS, ACTIONS, SUITS, PROCEEDINGS, LOSSES, OBLIGATIONS, DAMAGES, LIABILITIES, COSTS, DEBT AND EXPENSES (INCLUDING ATTORNEY’S FEES) ARISING OUT OF: (1)”YOUR USE OR MISUSE OF A DIGITAL WALLET OR THE INTELLECTUAL PROPERTY OF ANY THIRD PARTY; (2) YOUR VIOLATION OF THIS AGREEMENT OR ANY APPLICABLE LAW, RULE OR REGULATION; (3) YOUR VIOLATION OF THE RIGHTS OF ANY OTHER PERSON OR ENTITY, INCLUDING WITHOUT LIMITATION ANY RIGHT OF PRIVACY, PUBLICITY RIGHTS OR INTELLECTUAL PROPERTY RIGHTS; (4)”YOUR BREACH OF THE FOREGOING REPRESENTATIONS, WARRANTIES AND COVENANTS; (5) ANY MISREPRESENTATION MADE BY YOU IN CONNECTION WITH YOUR USE OF A DIGITAL WALLET OR CARD; AND/OR (6) ANY OTHER PARTY’S ACCESS TO OR USE OF A DIGITAL WALLET WITH YOUR USER ID, PIN, BIOMETRIC AUTHENTICATION OR OTHER APPROPRIATE SECURITY CODE. WE RESERVE THE RIGHT, AT YOUR EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER FOR WHICH YOU ARE REQUIRED TO INDEMNIFY US, AND YOU AGREE TO COOPERATE WITH OUR DEFENSE OF THESE CLAIMS. YOU AGREE NOT TO SETTLE ANY MATTER OR ADMIT GUILT WITHOUT OUR PRIOR WRITTEN CONSENT. WE WILL USE REASONABLE EFFORTS TO NOTIFY YOU OF ANY SUCH CLAIM, ACTION OR PROCEEDING UPON BECOMING AWARE OF IT.
        </p>
    </section>
    <section class="section-separator">
        <h2>XIV. Binding Arbitration, Class Action Waiver and Jury Trial Waiver</h2>
        <p>
            YOU AND US BOTH AGREE TO RESOLVE ANY AND ALL DISPUTES, CONTROVERSIES OR CLAIMS THAT IN ANY WAY ARISE OUT OF OR RELATE TO THIS AGREEMENT (“DISPUTE(S)”) ONLY BY ARBITRATION IN ACCORDANCE WITH THE ARBITRATION PROVISION SET FORTH IN THE CARDHOLDER AGREEMENT. YOU UNDERSTAND THAT BY AGREEING TO THIS AGREEMENT, ARBITRATION WILL BE THE SOLE AND EXCLUSIVE MEANS OF RESOLVING ANY DISPUTE BETWEEN US. YOU ALSO UNDERSTAND THAT BY AGREEING TO THIS AGREEMENT, YOU AND US ARE GIVING UP THE RIGHT TO BRING A CLAIM IN COURT OR IN FRONT OF A JURY (AND THAT YOU AND US ARE GIVING UP THE RIGHT TO PROCEED WITH ANY CLASS ACTION OR OTHER REPRESENTATIVE ACTION. CLASS ACTION WAIVER. YOU AGREE TO WAIVE THE RIGHT TO BRING ANY CLASS OR COLLECTIVE ACTION. THIS AGREEMENT DOES NOT ALLOW CLASS OR COLLECTIVE ARBITRATIONS EVEN IF THE ARBITRATION RULES GOVERNING THE ARBITRATION PROCEDURES WOULD. NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, THE ARBITRATOR MAY AWARD MONEY OR INJUNCTIVE RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE THE RELIEF WARRANTED BY THAT PARTY’S INDIVIDUAL CLAIM. ARBITRATION OR COURT PROCEEDINGS HELD UNDER THIS AGREEMENT CANNOT BE BROUGHT, MAINTAINED OR RESOLVED ON BEHALF OF OR BY A CLASS, AS A PRIVATE ATTORNEY GENERAL OR IN ANY OTHER REPRESENTATIVE CAPACITY. IN ADDITION, INDIVIDUAL PROCEEDINGS CANNOT BE COMBINED WITHOUT THE CONSENT OF ALL OF THE PARTIES. ANY QUESTION REGARDING THE ENFORCEABILITY OR INTERPRETATION OF THIS PARAGRAPH WILL BE DECIDED BY A COURT AND NOT THE ARBITRATOR.
        </p>
        <p>
            JURY TRIAL WAIVER. IF FOR ANY REASON A DISPUTE PROCEEDS IN COURT RATHER THAN THROUGH ARBITRATION, YOU AND US AGREE THAT THERE WILL NOT BE A JURY TRIAL. YOU AND US UNCONDITIONALLY WAIVE ANY RIGHT TO TRIAL BY JURY IN ANY ACTION, PROCEEDING OR COUNTERCLAIM IN ANY WAY ARISING OUT OF OR RELATING TO THIS AGREEMENT. IN THE EVENT OF LITIGATION, THIS PARAGRAPH MAY BE FILED TO SHOW A WRITTEN CONSENT TO A TRIAL BY THE COURT.
        </p>
    </section>

    <section class="section-separator">
        <h2>XV. Intellectual Property Rights</h2>
        <p>
            You are granted a nonexclusive, non-sublicensable, nontransferable, personal, limited license to add and use your Card via Digital Wallet on your Supported Device solely in accordance with this Agreement and any other applicable Third-Party Agreement, including those related to the Digital Wallet. All intellectual property rights in a Digital Wallet are owned either by the Digital Wallet Provider, us, our licensors or third parties. Nothing in this Agreement gives you any rights in respect of any intellectual property owned by the Digital Wallet Provider or third party. Unless otherwise explicitly set forth in this Section, we grant you no other license rights to ours or any other party’s intellectual property.
        </p>
    </section>

    <section class="section-separator">
        <h2>XVI. Governing Law</h2>
        <p>
            This Agreement is governed by federal law and, to the extent that state law applies, the laws of the state of Tennessee regardless of where you may reside or use your Card at any time.
        </p>
    </section>
    <section class="section-separator">
        <h2>XVII. Communication</h2>
        <p>
            As a condition to activating and using your Cards in a Digital Wallet, you consent to receive notifications and automatically dialed calls or text messages on your Supported Device from us, including at the phone number you provide us. You also agree to receive notices and other communications from us by email to the email address on file for the relevant Card account, posting within your online account, or through the messaging capabilities of each Digital Wallet. If at any time you revoke any communication consent provided herein, we may suspend or cancel your ability to use your Cards in connection with a Digital Wallet. 
        </p>
        <p>
            You may receive push notifications from a Digital Wallet from time to time reflecting your Card account activity. If you do not wish to receive notifications, you may turn off these notifications through the device settings on your Supported Device.
        </p>
    </section>
</main>
