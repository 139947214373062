import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // console.log(window.pageYOffset, window.innerHeight)
    if(window.pageYOffset > 5.2 * window.innerHeight) {
      this.buttonVisible = false;
    } else {
      this.buttonVisible = true;
    }
  }

  title = 'Incodus-website';
  buttonVisible: boolean = true;
}
