import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { EmailJSResponseStatus } from '@emailjs/browser';
import emailjs from 'emailjs-com';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private snackbar: MatSnackBar) { }

  contactForm = new FormGroup({
    name: new FormControl(null,[Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+')]),
    number: new FormControl(null,[Validators.required, Validators.pattern(/^[1-9]+[0-9]*$/)]),
    email: new FormControl(null,[Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$')]),
    query: new FormControl(null,[Validators.required]),
  })

  ngOnInit(): void {
  }

  onReset = () => {
    this.contactForm.reset()
  }
  onSubmit = () => {
    console.log(this.contactForm.value);
    emailjs.send(environment.serviceId, environment.templateId, this.contactForm.value, environment.publicKey).then(
      (result: EmailJSResponseStatus) => {
        this.snackbar.open(
          'Thank you for reaching out, we will reach back to you as soon as possible',
          'Dismiss',
          { duration: 3500, verticalPosition: 'top' }
        );
        console.log(result)
      },
      (error) => {
        console.log(error);
        this.snackbar.open(error.text, 'Dismiss', { duration: 3000 });
      }
    );
  }

}
