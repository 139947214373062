import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServicesService {

  constructor(private http: HttpClient) { }

  joinTheWaitlist(email) {
    const url = "https://0btyqlwaje.execute-api.us-east-1.amazonaws.com/prod/email";
      try {
        return this.http.post(
          url, 
          {
              email: email
          }
        )
      } catch(err) {
        return null;
      } finally {
        const target = ( <any>window ); // <===== THIS IS THE WORK AROUND
        if (typeof target.dataLayer !=="undefined") 
        target.dataLayer.push({ 'event' :  'WaitlistSignup' });
      }
    }
}
