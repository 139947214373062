<div class="faq-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="heading mt-80 mb-20" fxLayout="column" fxLayoutAlign="center center">
    Frequently Asked Questions
  </div>
  <div class="sub-heading mb-60">
    If you can’t find the answer to your question, please <a [routerLink]="'/contact-us'"> <u>contact us</u></a>
    so we can help!
  </div>

  <div class="width-85 panel-container">
    <mat-accordion multi="true" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">

      <div fxLayout="column" fxFlex="49">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              I am not comfortable sharing my idea with anyone else, do you sign NDA ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            Yes, of course we do. We won’t share your idea or project data with anyone else unless it is against the
            policy of the company or against the interest of the general public.
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              What is the typical cost of an average project?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            <div>
              The project cost depends upon a variety of factors: <br>
              1) Scale and scope of the project, <br>
              2) Technology involved, <br>
              3) Whether logic is involved or not, <br>
              4) Animations to be used, <br>
              5) Urgency of the project / delivery deadline, <br>
              6) Design services included or not

            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              What inputs and data clients need to provide?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            The project usually starts with a detailed interview with the clients. During the interview, we discuss in
            detail the following subjects: <br>
            1) Business/organisational goals <br>
            2) Target audience details <br>
            3) Product goals <br>
            4) Expected outcomes and KPIs of the project <br>
            5) Technical specifications <br>
            6) Market research data, if any <br>
            7) Previous user experience audit results if any <br>
            8) User analytics data, if any <br>
            9) User feedbacks <br>

          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              Do you provide design implementation support as a part of the project deliverable?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            Most of the work we do is within the preview of development of websites, but if the client asks, we can help
            with the design implementation by providing them with various template ideas.
          </div>
        </mat-expansion-panel>
      </div>

      <div fxLayout="column" fxFlex="49">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              How we'd know if quality work will be provided ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            Before we begin our relationship pertaining to the subjected project, we’ll provide you with a small mockup
            that will let you know the quality of the work that is being provided by Incodus Technologies.
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              Do you provide support after the project is delivered, if yes, is it chargeable?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            Yes, we do provide the support after the delivery of the project. The charge of the support will be decided
            based on the nature and scale of the change that is required to be carried out.
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              What if the project is not delivered within the stipulated time ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            The possibility of this happening is very marginal. There are 2 circumstances to be considered if delay is
            there: <br>
            1) If the delay is caused by the company, then we’ll try to deliver is as soon as possible <br>
            2) If the delay is caused by the client, then for the same the company will not be held responsible.
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="faq-question">
              What are the payment terms?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-answer">
            1) If the project is under $1000, then 50% advance is required to be paid at the time of finalisation of the
            project. <br>
            2) If the project is above $1000, then 25% advance is required to be paid at the time of finalisation of the
            project.
          </div>
        </mat-expansion-panel>
      </div>

    </mat-accordion>
  </div>

</div>