import { DownloadButtonService } from '../../services/download-button.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { InViewportMetadata } from 'ng-in-viewport';
declare const fbq: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  isSticky: boolean = false;
  isDescSticky: boolean = false;
  currentService: number = 1;
  
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    
    if(window.innerHeight < 950) {
      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - 80) && window.pageYOffset <= (document.getElementById("service5").offsetTop - 70)) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }

    } else if (window.innerHeight > 950 && window.innerHeight < 1399) {

      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight*0.25)) && window.pageYOffset <= (document.getElementById("service5").offsetTop)) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
        document.getElementById("s5Image").style.marginTop = "0px";
      }

      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight*0.25)) && window.pageYOffset <= (document.getElementById("sSubHeading").offsetTop + document.getElementById("sSubHeading").offsetHeight + 20)) {
        this.isDescSticky = true;
      } else {
        this.isDescSticky = false;
      }

      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight*0.25))) {
        document.getElementById("s1Desc").style.marginTop = "-70px";
      } else {

       document.getElementById("s1Desc").style.marginTop = (window.innerHeight*-0.45) + "px";
      }

      if(window.innerWidth > 1199 && window.innerWidth < 1499 && this.isDescSticky) {
        document.getElementById("s1Desc").style.width = document.getElementById("s2Desc").clientWidth + "px";
        document.getElementById("s1Desc").style.left = (document.getElementById("s2Desc").offsetLeft + document.getElementById("service1").offsetLeft) + "px";
      }

    } else if(window.innerHeight >= 1399) {
      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight*0.31)) && window.pageYOffset <= ((document.getElementById("service5").offsetTop) )) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
        document.getElementById("s5Image").style.marginTop = "0px";
      }

      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight*0.31)) && window.pageYOffset <= (document.getElementById("sSubHeading").offsetTop + document.getElementById("sSubHeading").offsetHeight + 20)) {
        this.isDescSticky = true;
      } else {
        this.isDescSticky = false;
      }

      if(window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight*0.31))) {
        document.getElementById("s1Desc").style.marginTop = "-70px";
      } else {
        document.getElementById("s1Desc").style.marginTop = (window.innerHeight*-0.6) + "px";
      }
    }



    if(window.innerHeight < 1399) {
      if (window.pageYOffset >= (document.getElementById("service1").offsetTop - 400) && window.pageYOffset <= (document.getElementById("service2").offsetTop - 400)) {
        this.currentService = 1;
      } else if (window.pageYOffset >= (document.getElementById("service2").offsetTop - 400) && window.pageYOffset <= (document.getElementById("service3").offsetTop - 400)) {
        this.currentService = 2;
      }  else if (window.pageYOffset >= (document.getElementById("service3").offsetTop - 400) && window.pageYOffset <= (document.getElementById("service4").offsetTop - 400)) {
        this.currentService = 3;
      } else if (window.pageYOffset >= (document.getElementById("service4").offsetTop - 400) && window.pageYOffset <= (document.getElementById("service5").offsetTop - 400)) {
        this.currentService = 4;
      } else if (window.pageYOffset >= this.calculateHeight("service5")) {
        this.currentService = 5;
      }
    } else if(window.innerHeight >= 1399) {
      if (window.pageYOffset >= (document.getElementById("service1").offsetTop - (window.innerHeight * 0.4)) && window.pageYOffset <= (document.getElementById("service2").offsetTop - (window.innerHeight * 0.4))) {
        this.currentService = 1;
      } else if (window.pageYOffset >= (document.getElementById("service2").offsetTop - (window.innerHeight * 0.4)) && window.pageYOffset <= (document.getElementById("service3").offsetTop - (window.innerHeight * 0.4))) {
        this.currentService = 2;
      }  else if (window.pageYOffset >= (document.getElementById("service3").offsetTop - (window.innerHeight * 0.4)) && window.pageYOffset <= (document.getElementById("service4").offsetTop - (window.innerHeight * 0.4))) {
        this.currentService = 3;
      } else if (window.pageYOffset >= (document.getElementById("service4").offsetTop - (window.innerHeight * 0.4)) && window.pageYOffset <= (document.getElementById("service5").offsetTop - (window.innerHeight * 0.4))) {
        this.currentService = 4;
      } else if (window.pageYOffset >= this.calculateHeight("service5")) {
        this.currentService = 5;
      }
    }


  }

  calculateHeight = (elementID) => {
    if(window.innerHeight < 1399) {
      return (document.getElementById(elementID).offsetTop + (Number(document.getElementById(elementID).style.height) - 400))
    } else {
      return (document.getElementById(elementID).offsetTop + (Number(document.getElementById(elementID).style.height) - (window.innerHeight * 0.6)))
    }
  }


  goToStore = () => {
    try {
      fbq('trackCustom', 'WaitlistSignup');
    } catch (err) {
      console.error(err);
    };
    console.log("redirect");
     window.open('https://awsmbank.com/l.html', '_blank');
  };


  
  // goToPlayStore = () => {
  //   fbq('trackCustom', 'WaitlistSignup');
  //    window.open('https://play.google.com/store/apps/details?id=cards.awsm.v2', '_blank');
  // }
  // goToAppStore = () => {
  //   fbq('trackCustom', 'WaitlistSignup');
  //   window.open('https://apps.apple.com/us/app/awsm-banking/id1514517710', '_blank');
  // }

  apiStatus: string;

  constructor(
    public apiServicesService: ApiServicesService,
    public downloadButtonService: DownloadButtonService
  ) {
  }

  leaderCardArray = [
    {
      img: '../../../assets/images/howitworks-3-icon.svg',
      number: '01',
      personName: 'Deep shah',
      personDesc: `He is basically an engineer but always had a keen interest in computers since he was a child. He didn’t give up on his dream and ended being a Full stack developer and is now helping people to achieve their dream.`,
    },
    {
      img: '../../../assets/images/howitworks-3-icon.svg',
      number: '02',
      personName: 'Shail Shah',
      personDesc: `He’s a Company Secretary, lawyer and also a Front-end developer. His mentality of not giving up and exploring new areas of knowledge has helped his career very much. He’s always keen to help others and learn from them.`,
    },
    {
      img: '../../../assets/images/howitworks-3-icon.svg',
      number: '03',
      personName: 'Aman Shah',
      personDesc: `Aman is a young talent. He’s pursuing BCA and is in his last year. He always wanted to develop something related to technology. After starting as a back-end developer, now he’s a Front-end developer as he knows that one of the main strengths of his is logic and it will be more helpful if he’s a Front-end developer.`,
    },
  ];

  ngOnInit(): void {
  }

  joinWaitlist = (emailID) => {
    if(emailID.length) {
        this.apiServicesService.joinTheWaitlist(emailID).subscribe((res: any) => {
          if(res.statusCode === 200) {
            this.apiStatus = 'success';
          } else {
            this.apiStatus = 'failure';
          }
          setTimeout(() => {
            this.apiStatus ='';
          }, 5000);
        });
    }
  }

  action(event: any){
    const { [InViewportMetadata]: { entry }, target, visible } = event;
    this.downloadButtonService.setIsVisible(visible);
  }
}
