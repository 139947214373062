<div fxLayout="column" fxLayoutAlign="center center">
    <div class="heading mb-90 mt-80" fxLayout="column" fxLayoutAlign="center center">
        Get Started In 6 Steps
    </div>

    <div class="width-70 mb-50 container" fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between">
        <div fxFlex="45">
            <img class="how-it-works-image" [src]="'assets/images/understanding-project.svg'">
        </div>
        <div class="get-started-card" fxFlex="40" fxFlex.sm="50" fxLayout="column" fxLayoutAlign="center flex-start">
            <img class="image mb-20" [src]="'assets/images/howitworks-1-icon.svg'">
            <div class="heading mb-15">
                Understanding the project
            </div>
            <div class="description">
                The basic step for a project to be successful is to understand the requirements of the client. Also we
                would present a Proof of Concept so that we both can understand the needs of each other and work
                accordingly to create seamless websites.
            </div>
        </div>
    </div>

    <div class="width-70 mb-50 container" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between">
        <div class="get-started-card" fxFlex="40" fxFlex.sm="50" fxLayout="column" fxLayoutAlign="center flex-start">
            <img class="image mb-20" [src]="'assets/images/howitworks-2-icon.svg'">
            <div class="heading mb-15">
                Setting goals
            </div>
            <div class="description">
                After understanding the project, a proper timeline would be set for the completion of the goals set.
            </div>
        </div>
        <div fxFlex="45">
            <img class="how-it-works-image" [src]="'assets/images/setting-goal.svg'">
        </div>
    </div>

    <div class="width-70 mb-50 container" fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between">
        <div fxFlex="45">
            <img class="how-it-works-image" [src]="'assets/images/coding-testing.svg'">
        </div>
        <div class="get-started-card" fxFlex="40" fxFlex.sm="50" fxLayout="column" fxLayoutAlign="center flex-start">
            <img class="image mb-20" [src]="'assets/images/howitworks-3-icon.svg'">
            <div class="heading mb-15">
                Coding and testing
            </div>
            <div class="description">
                Now, the main work will take place and the developers will develop the code which in turn will be tested
                by the testers.
            </div>
        </div>
    </div>

    <div class="width-70 mb-50 container" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between">
        <div class="get-started-card" fxFlex="40" fxFlex.sm="50" fxLayout="column" fxLayoutAlign="center flex-start">
            <img class="image mb-20" [src]="'assets/images/howitworks-4-icon.svg'">
            <div class="heading mb-15">
                Feedback
            </div>
            <div class="description">
                A mock would be presented to the client to gain the feedback and make changes accordingly. This process
                will go back and forth until it is finalised.
            </div>
        </div>
        <div fxFlex="45">
            <img class="how-it-works-image" [src]="'assets/images/feedback.svg'">
        </div>
    </div>

    <div class="width-70 mb-50 container" fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between">
        <div fxFlex="45">
            <img class="how-it-works-image" [src]="'assets/images/deployment.svg'">
        </div>
        <div class="get-started-card" fxFlex="40" fxFlex.sm="50" fxLayout="column" fxLayoutAlign="center flex-start">
            <img class="image mb-20" [src]="'assets/images/howitworks-5-icon.svg'">
            <div class="heading mb-15">
                Deployment / Handing over
            </div>
            <div class="description">
                After the project is finalized by the client, it will be handed over to them.
            </div>
        </div>
    </div>

    <div class="width-70 mb-50 container" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between">
        <div class="get-started-card" fxFlex="40" fxFlex.sm="50" fxLayout="column" fxLayoutAlign="center flex-start">
            <img class="image mb-20" [src]="'assets/images/howitworks-6-icon.svg'">
            <div class="heading mb-15">
                After sales service
            </div>
            <div class="description">
                We also provide the client with after sales service without charging anything if the change that will be
                carried out is minor and does not take up much of our time and effort.
            </div>
        </div>
        <div fxFlex="45">
            <img class="how-it-works-image" [src]="'assets/images/after-sales.svg'">
        </div>
    </div>
</div>