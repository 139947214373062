<div fxLayout="column" fxLayoutAlign="center center">
    <div class="heading mt-80 mb-10" fxLayout="column" fxLayoutAlign="center center">
        Terms &amp; Policies
    </div>
    <div class="sub-heading mb-90">
        Last Updated December 31st, 2019
    </div>

    <div class="link-card mb-25" fxLayout="row" fxLayoutAlign="space-between center" [routerLink]="['/terms-of-use']">
        <div class="link">
            Terms of Use
        </div>
        <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
    </div>

    <div class="link-card mb-25" fxLayout="row" fxLayoutAlign="space-between center" [routerLink]="['/privacy-policy']">
        <div class="link">
            Privacy Policy
        </div>
        <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
    </div>

    <div class="link-card mb-25" fxLayout="row" fxLayoutAlign="space-between center" [routerLink]="['/deposit-agreement']">
        <div class="link">
            Deposit Agreement
        </div>
        <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
    </div>

    <a href="https://www.getevolved.com/privacy-policy/" target="_blank" class="link-card mb-25" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <div > -->
            <div class="link">
                Evolve Privacy Policy
            </div>
            <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
        <!-- </div> -->
    </a>

    <div class="link-card mb-25" fxLayout="row" fxLayoutAlign="space-between center" [routerLink]="['/cardholder-agreement']">
        <div class="link">
            Cardholder Agreement
        </div>
        <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
    </div>

    <div class="link-card mb-25" fxLayout="row" fxLayoutAlign="space-between center" [routerLink]="['/digital-wallet-agreement']">
        <div class="link">
            Digital Wallet Terms and Conditions
        </div>
        <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
    </div>

    <div class="link-card mb-120" fxLayout="row" fxLayoutAlign="space-between center" [routerLink]="['/electronic-communication']">
        <div class="link">
            Electronic Communication Consent
        </div>
        <img class="icon" [src]="'assets/images/arrow-right-button.svg'">
    </div>
</div>