import { DownloadButtonService } from "../services/download-button.service";
import { Component, OnInit, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
declare const fbq: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  joinButtonSubscription: Subscription;
  joinButtonVisible: boolean = true;
  isPlaying: boolean = false;
  buttonVisible: boolean = false;
  logoVisible: boolean = true;
  screenWidth: number;
  sidenavOpened: boolean = false;
  sidenavOpenedHeader = false;
  pageScrolled = false
  screenWidthHeader = 0;

  constructor(public downloadButtonService: DownloadButtonService) {}

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if(window.pageYOffset > 0.05) {
      this.pageScrolled = true;
    } else {
      this.pageScrolled = false;
    }
  }
  ngOnInit(): void {
    this.downloadButtonService.isVisible.subscribe((value) => {
      this.joinButtonVisible = value;
    });

    this.downloadButtonService.isMobileLogoVisible.subscribe(logoVisible => this.logoVisible = logoVisible);

    this.screenWidth = window.innerWidth;
  }

  onVideoClick = () => {
    this.buttonVisible = true;
    setTimeout(() => {
      this.buttonVisible = false;
    }, 2000);
    let video: any = document.getElementById("bflat");
    if (typeof video !== "undefined" && video !== null) {
      if (video.paused) {
        video.play();
        this.isPlaying = true;
      } else {
        video.pause();
        this.isPlaying = false;
      }
    }
  };

  navigateToID = (ID) => {
    const id = ID;
    const yOffset = -100;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };


  goToStore = () => {
    console.log("redirect3");
    try {
      fbq('trackCustom', 'WaitlistSignup');
   } catch (err) {
     console.error(err);
   };
     window.open('https://awsmbank.com/l.html', '_blank');
  };


  // goToPlayStore = () => {
  //   fbq("trackCustom", "WaitlistSignup");
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=cards.awsm.v2",
  //     "_blank"
  //   );
  // };
  // goToAppStore = () => {
  //   fbq("trackCustom", "WaitlistSignup");

  //   window.open(
  //     "https://apps.apple.com/us/app/awsm-banking/id1514517710",
  //     "_blank"
  //   );
  // };

  openNav = () => {
    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("mySidenav").style.left = "0%";
  };

  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.left = "100%";
  };

  toggleNav = () => {
    this.sidenavOpened ? this.closeNavForMobile() : this.openNavForMobile();
  };

  openNavForMobile = () => {
    this.sidenavOpened = true;
    var elem = document.getElementById("mySidenav");
    if (typeof elem !== "undefined" && elem !== null) {
      elem.style.width = "100%";
      elem.style.left = "0%";
    }
  };

  closeNavForMobile = () => {
    this.sidenavOpened = false;
    var elem = document.getElementById("mySidenav");
    if (typeof elem !== "undefined" && elem !== null) {
      elem.style.width = "0%";
      elem.style.left = "100%";
    }
  };
}
