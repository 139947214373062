import { DownloadButtonService } from '../services/download-button.service';
import { Component, OnInit } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { InViewportMetadata } from 'ng-in-viewport';
declare const fbq: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  apiStatus: string;
  screenWidth: number;

  constructor(
    public apiServicesService: ApiServicesService,
    public downloadButtonService: DownloadButtonService
    ) { }
    
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  joinWaitlist = (emailID) => {
    if(emailID.length) {
        this.apiServicesService.joinTheWaitlist(emailID).subscribe((res: any) => {
          if(res.statusCode === 200) {
            this.apiStatus = 'success';
          } else {
            this.apiStatus = 'failure';
          }
          setTimeout(() => {
            this.apiStatus ='';
          }, 5000);
        });
    }
  }

  goToStore = () => {
    console.log("redirect2");
    try {
       fbq('trackCustom', 'WaitlistSignup');
    } catch (err) {
      console.error(err);
    };

     window.open('https://awsmbank.com/l.html', '_blank');
  };


  // goToPlayStore = () => {
  //   fbq('trackCustom', 'WaitlistSignup');
  //   // window.open('https://play.google.com/store/apps/details?id=cards.awsm.v2', '_blank');
  //   window.open('https://awsmbank.com/l.html', '_blank');
  // }
  // goToAppStore = () => {
  //   fbq('trackCustom', 'WaitlistSignup');
  //   window.open('https://apps.apple.com/us/app/awsm-banking/id1514517710', '_blank');
  // }

  action(event : any){
    const { [InViewportMetadata]: { entry }, target, visible } = event;
    this.downloadButtonService.setIsVisible(visible);
  }
}
