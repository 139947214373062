import { FaqComponent } from './pages/faq/faq.component';
import { TermsPoliciesComponent } from './pages/terms-policies/terms-policies.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from "./pages/home/home.component";
import { WhyComponent } from './pages/why/why.component';
import { AgreementComponent } from "./pages/agreement/agreement.component";
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ElectronicCommunicationComponent } from './pages/electronic-communication/electronic-communication.component';
import { DepositAgreementComponent } from './pages/deposit-agreement/deposit-agreement.component';
import { CardholderAgreementComponent } from './pages/cardholder-agreement/cardholder-agreement.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes: Routes = [
    {
      path: '',
      component: HomeComponent
    },
    {
      path: 'digital-wallet-agreement',
      component: AgreementComponent
    },
    {
      path: 'terms-of-use',
      component: TermsOfUseComponent
    },
    {
      path: 'privacy-policy',
      component: PrivacyPolicyComponent
    },
    {
      path: 'electronic-communication',
      component: ElectronicCommunicationComponent
    },
    {
      path: 'deposit-agreement',
      component: DepositAgreementComponent
    },
    {
      path: 'cardholder-agreement',
      component: CardholderAgreementComponent
    },
    {
      path: 'why-us',
      component: WhyComponent
    },
    {
      path: 'how-it-works',
      component: GetStartedComponent
    },
    {
      path: 'contact-us',
      component: ContactUsComponent
    },
    {
      path: 'terms-policies',
      component: TermsPoliciesComponent
    },
    {
      path: 'faq',
      component: FaqComponent
    },
    {
      path: '*',
      redirectTo: ''
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    useHash:true
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }