import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deposit-agreement',
  templateUrl: './deposit-agreement.component.html',
  styleUrls: ['./deposit-agreement.component.scss']
})
export class DepositAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
