<div fxLayout="column" fxLayoutAlign="center space-around" class="privacy-policy">
    <h1>Privacy Policy</h1>
    <h4 class="date-updated">Updated: 06/29/2019</h4>
    <section class="section-separator">
        <h2> Who we are </h2>
        <article>
            <p>
                <span class="mulish-bold">AWSM Brand Management Inc. (“AWSM,” “we”, “our”, or “us”)</span> is a financial services company. When you use these services, you’ll share some information with us that could be used to identify you or that is associated with information that identifies you (“Personal Information”). This Privacy Policy applies to Personal Information that we gather or collect via the Site, our mobile application or any related products and services (collectively, the “Services”). It does not apply to any Personal Information collected by third party websites not operated by AWSM. 
            </p>
            <p>
                By using our Services, you are agreeing to this policy. If you do not agree to these terms, you may not enroll in or use the Services. Pursuant to this Privacy Policy, you direct our partner financial institution(s) to share your transactional data with AWSM, and you direct AWSM to share your data with its users, vendors, partners, and affiliates at its discretion, in a way that would otherwise be contrary to this policy. 
            </p>
            <p>
                If after reading this Privacy Policy you still have questions about anything in it, please contact us at <a href="privacy@awsmbank.com"> <u> privacy@awsmbank.com</u> </a>.
            </p>
        </article>
    </section>

    <section class="section-separator">
        <h2> The information we collect </h2>
        <article>
            <p>
                There are three basic categories of information we collect:
            </p>
            <ul>
                <li> Personal Information you provide to us </li>
                <li> Automatically-collected data </li>
                <li> Information we get from third parties </li>
            </ul>
        </article>
    </section>

    <section class="section-separator">
        <h2> Personal Information you provide to us </h2>
        <article>
            <p>
                When you interact with our Site or mobile app, we collect Personal Information that you choose to share with us. For example, when you set up an account with us, we need to collect a few important details about you, such as your name, a social security number, a phone number, your mailing address, your email address, and your date of birth. We may also collect other information such as a copy of your government-issued identification and a selfie image for verification purposes. Please ensure that you only send us an image of yourself. Do not include any images of others. To open a business account the collection of business information may include, but is not limited to, your business name, tax identification number, business address, business telephone number, business bank account information, business related documentation, and telephone number, or email address. 
            </p>
            <p>
                Additional information will be requested at account opening and may be requested throughout the life of the account relationship to meet regulatory and legal requirements. If you choose to provide us with personal information, business information, financial information, and financial data, you consent to the transfer and storage of that information on our servers located within the United States. When you contact customer support or communicate with us in any other way, we’ll collect whatever information you volunteer, in addition to your name and email address.
            </p>
        </article>
    </section>

    <section class="section-separator">
        <h2> Automatically-collected data </h2>
        <article>
            <p>
                When you visit our Site or use our Services, we collect information about which of the Services you’ve used and how you’ve used them. Examples of this information include:
            </p>
            <ul>
                <li> Usage information on our Site or mobile app (e.g., pages you visit on the Site, webpage from which you linked to our Site); </li>
                <li> Time and date of your activities; </li>
                <li> Location from which you browsed the Site; </li>
                <li> Device ID; </li>
                <li> Your IP address; </li>
                <li> The kind of browser and computer you use; </li>
                <li> Name of your internet service provider; and</li>
                <li> Operating system version. </li>
            </ul>
        </article>
    </section>

    <section class="section-separator">
        <h2> Information we get from third parties </h2>
        <article>
            <p>
                We may collect Personal Information about you from other users, our affiliates, and third parties, as permitted by law. For example, we may collect information from third-party verification services, credit bureaus, financial institutions, mailing list providers, and publicly available sources to verify your identity. We may also collect information about you from third parties in connection with any identity or account verification process, fraud detection process, or collection procedure, or as may otherwise be required by applicable law.
            </p>
        </article>
    </section>

    <section class="section-separator">
        <h2> How we use your information </h2>
        <article>
            <h3> Personal Information </h3>
            <p>
                <span class="mulish-bold">To provide the Services: </span>
            </p>
            <p>
                AWSM may use your Personal Information to fulfill requests for products, Services, or information, including information about potential or future Services, including to:
            </p>
            
            <ul>
                <li> Manage individual information and accounts; </li>
                <li> Respond to questions, comments, and other requests; </li>
                <li> Verify an individual’s identity by comparing your selfie image against your government-issued identification; </li>
                <li> Process payment for products or Services purchased; </li>
                <li> Process applications and transactions; </li>
                <li> Provide access to certain areas, functionalities, and features of our Services; and </li>
                <li> Allow you to register for events. </li>
            </ul>
        </article>

        <article>
            <p>
                <span class="mulish-bold"> For internal business purposes: </span>
            </p>
            <p>
                AWSM may use Personal Information for its internal business purposes, including to:
            </p>
            
            <ul>
                <li> Measure interest in AWSM’s Services; </li>
                <li> Develop new products and Services or improve existing products and Services; </li>
                <li> Ensure internal quality control; </li>
                <li> Communicate about individual accounts and activities on AWSM’s Services and systems, and, in AWSM’s discretion, changes to any AWSM policy; </li>
                <li> Comply with regulatory requirements; </li>
                <li> Prevent potentially prohibited or illegal activities; </li>
                <li> Enforce our Terms of Use; </li>
                <li> Protect the safety of any person, to address fraud, security or technical issues, or to protect the Company’s rights or property; </li>
                <li> Comply with applicable laws, rules and regulations or court orders; and </li>
                <li> For any other purpose for which you have given express permission or consent to AWSM. </li>
            </ul>
        </article>

        <article>
            <p>
                <span class="mulish-bold"> For marketing purposes: </span>
            </p>
            <p>
                AWSM may analyze and use Personal Information to provide you with materials about offers, products, and Services that may be of interest, including new content or Services. AWSM may provide you with these materials by phone, postal mail, email, as permitted by applicable law. Such uses include:
            </p>
            
            <ul>
                <li> To tailor content, advertisements, and offers; </li>
                <li> To notify you about offers, products, and services that may be of interest to you; </li>
                <li> To provide Services to you and our sponsors; </li>
                <li> For other purposes disclosed at the time you provide Personal Information; and </li>
                <li> Otherwise with your consent. </li>
            </ul>
        </article>

        <article>
            <h3> Non-Personal Information </h3>
            <p>
                We may also use non-personally identifiable information that does not personally identify you, including by aggregating and/or de-identifying Personal Information, in order to understand better how our visitors use the Site, research our visitors’ demographics, interests, and behavior, improve the Site, provide visitors with customized services and information, and for other similar purposes. We may share this information with others for information or promotional purposes, and may use non-personally identifiable information in any manner permitted by law. When any non-personally identifiable information is combined with other information that identifies you, it will be treated as Personal Information in accordance with this Privacy Policy.
            </p>
        </article>
    </section>

    <section class="section-separator">
        <h2> How we share information </h2>
        <article>
            <p>
                We may share Personal Information about you in the following ways:
            </p>

            <ul>
                <li> 
                    To verify your identity. For example, we may share information about you with credit reporting agencies to verify your Social Security number.
                </li>
                <li> 
                    With vendors and service providers for: (i) provision of IT and related services; (ii) provision of information and Services you have requested; (iii) payment processing; and (iv) customer service activities; (v) fraud prevention; (vi) analytics services; and (vii) in connection with the provision of the Services. These service providers have contracted with us to only use the Personal Information for the purpose(s) for which the data were originally collected or may otherwise be lawfully processed. 
                </li>
                <li> 
                    Business partners. We may share Personal Information with our business partners, affiliates, and for our affiliates’ internal business purposes or to provide you with a product or service that you have requested. We may also share Personal Information with business partners with whom we may jointly offer products or services, or whose products or services we believe may be of interest to you. 
                </li>
                <li> 
                    With third parties for legal reasons. We may share information about you if we reasonably believe that disclosing the information is needed to:
                    <ul>
                        <li> 
                            Comply with any valid legal process, governmental request, or applicable law, rule, or regulation; 
                        </li>
                        <li> 
                            Investigate, remedy, or enforce potential Terms of Service violations; 
                        </li>
                        <li> 
                            Protect the rights, property, and safety of us, our users, or others; or 
                        </li>
                        <li> 
                            Detect and resolve any fraud or security concerns. 
                        </li>
                    </ul>   
                </li>
                <li> 
                    With third parties as part of a merger or acquisition. In the event of a transaction or proposed transaction involving the transfer of substantially all of the assets of AWSM or one or more of its businesses to another entity, whether an affiliate or a third party, or in connection with a bankruptcy, we may share your Personal Information in the diligence process or to otherwise facilitate the transaction, and with individuals assisting in the transaction or in connection with a bankruptcy. Your Personal Information may also be one of the transferred assets as part of the transaction or bankruptcy. 
                </li>
            </ul>
        </article>
    </section>

    <section class="section-separator">
        <h2> Cookies & analytics technologies </h2>
        <article>
            <p>
                We may also use various technologies to collect and store information related to your use of our Services. We may collect and store information locally on your device using mechanisms such as browser web storage and application data caches. A cookie is a text file that our website transfers to your device’s hard drive for record-keeping purposes and to enhance the quality of your visit to our website. We use both “session” cookies, which last only until you close your browser, and “persistent” cookies, which last until you or your browser delete them. We use cookies and other technologies to personalize our Services for you and to collect information about usage of our Services. 
            </p>
            <p>
                Some of our business partners (e.g., advertisers) may also use cookies on the Site. For example, on occasion an advertiser may run a banner campaign on our Site. This Privacy Policy covers the use of cookies by this Site only and does not cover the use of cookies by any business partners or advertisers on our Site. 
            </p>
            <p>
                We may also use analytics tools such as Google Analytics to collect information regarding visitor behavior and visitor demographics on our Site, and to develop website content. This analytics data is not tied to any Personal Information. For more information about Google Analytics, please visit <a href="https://policies.google.com/technologies/partner-sites" target="_blank">https://policies.google.com/technologies/partner-sites</a>. You can opt out of Google’s collection and Processing of data generated by your use of the Site by going to <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">http://tools.google.com/dlpage/gaoptout</a>.
            </p>
        </article>
    </section>

    <section class="section-separator">
        <h2> How long we keep your information </h2>
        <article>
            <p>
                We will retain your information for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our products and Services, resolve disputes, establish legal defenses, conduct audits, enforce our agreements, and comply with applicable laws.
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> External Sites </h2>
        <article>
            <p>
                Our Services may contain links to other websites and other websites may reference or link to our Services. These websites are operated by third parties not controlled by us, and by linking to them or being linked from them AWSM does not endorse, approve or make any representations about Third-Party websites or domains. We encourage you to read the privacy policies of each and every website and domain that you interact with. We are not responsible for the privacy practices or content of such other websites or domains and you visit them at your own risk.
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> Social Media </h2>
        <article>
            <p>
                Our Services may contain links and interactive features with various social media platforms. If you already use these platforms, their cookies may be set on your device when using our Services. You should be aware that Personal Information which you voluntarily include and transmit online in a publicly accessible social media platform, blog, or chat room, or otherwise online, or that you share in an open forum, may be viewed and used by others without any restrictions. We have no control over such uses of your information when interacting with a social media platform, forums or otherwise online and by using such services you assume the risk that the Personal Information provided by you may be viewed and used by third parties. 
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> Security </h2>
        <article>
            <p>
                AWSM takes commercially-reasonable steps to protect the data you have given us from accidental or intentional manipulation, loss, destruction or access by unauthorized persons. However, please be aware that no transmission of Personal Information over the Internet or the electronic storage of this information can be absolutely guaranteed to be free from unauthorized use or access. Before submitting any Personal Information via the Site or Services, please keep in mind these risks and know that you do so at your own risk.
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> Your Choices </h2>
        <article>
            <h2> Communications Preferences: </h2>
            <p>
                 If you no longer want to receive email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. You will continue to receive transaction-related emails regarding products or services you have requested. We may also send you certain communications regarding AWSM and you will not be able to opt out of those communications (e.g., communications regarding updates to our Privacy Policy).
            </p>
            <h2>Sharing Preferences</h2>
            <p>
                 We may give you choices regarding the sharing of your information with affiliates and third parties. Choices you have about the sharing of your information will be described in the privacy policies or notices you receive in connection with the products or Services you obtain from us. 
            </p>
            <h2> Changes to this Privacy Policy </h2>
            <p>
                We may change this Privacy Policy from time to time. If we do, we will post the revised policy on this page with a new “Last Updated” date. All changes will become effective when posted unless indicated otherwise. If we make any material changes we may choose to post a notice on this website prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
            </p>
        </article>
    </section>

    <section class="section-separator">
        <h2> Other Rights and Important Information </h2>
        <article>
            <h2> Children’s Privacy </h2>
            <p>
                 We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services are directed to children under the age of 13. If you learn that your child has provided us with Personal Information without your consent, please let us know by contacting us at <a href="mailto:support@awsmbank.com"> <u> support@awsmbank.com</u> </a>. If we learn that we have collected any personal information from children under 13, we will use commercially-reasonable efforts to delete such information.
            </p>
            <h2> Your California Privacy Rights</h2>
            <p>
                California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the Third Parties to whom we have disclosed their Personal Information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of Personal Information disclosed to those parties. AWSM does not share your Personal Information with third parties for their direct marketing purposes. 
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> How We Respond to “Do Not Track” Signals </h2>
        <article>
            <p>
                Our Site currently does not respond to “Do Not Track” (DNT) signals and operates as described in this Privacy Policy whether or not a DNT signal is received. If we do so in the future, we will describe how we do so in this Privacy Policy.
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> Indemnification </h2>
        <article>
            <p>
                You are entirely responsible for maintaining the confidentiality of your security pin(s) and your account(s), as well as all activities that occur under your account(s). You hereby agree to indemnify, defend, and hold us, our agents, representatives and other authorized users, and each of the foregoing entities’ respective officers, directors, owners, employees, agents, representatives and assigns (collectively the “Indemnified Parties”) harmless from and against any and all losses, damages, liabilities and costs incurred by the Indemnified Parties in connection with any claim arising out of any breach by you of this Privacy Policy or claims arising from our Services. You agree to use your best efforts to cooperate with us in the defense of any claim. We reserve the right, at our own expense, to employ separate counsel and assume the exclusive defense and control of any matter otherwise subject to indemnification by you.
            </p>
        </article>
    </section>
    
    <section class="section-separator">
        <h2> Contact Us </h2>
        <article>
            <p>
                If you have questions or comments about this Privacy Policy, please contact us at <a href="privacy@awsmbank.com">privacy@awsmbank.com</a>.
            </p>
        </article>
    </section>
    
    
</div>
