import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class DownloadButtonService {
    private visible = new BehaviorSubject<boolean>(true);
    isVisible = this.visible.asObservable();

    private mobileLogoVisible = new BehaviorSubject<boolean>(true);
    isMobileLogoVisible = this.mobileLogoVisible.asObservable();
    
    isiOS: boolean;
    isAndroid: boolean;
    isNotAndroidNotiOS: boolean;

    constructor() {
      this.isiOS =  [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

      this.isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

      this.isNotAndroidNotiOS = !this.isiOS && !this.isAndroid;
    }

    setIsVisible = (value) => {
        this.visible.next(value);
    }

    setMobileLogoVisible = (value) => {
      this.mobileLogoVisible.next(value);
    }


  

}
