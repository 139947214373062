<div class="home-container" fxLayout="column" fxLayoutAlign="center space-around">
  <div class="heading mb-25 heading-tablet">
    We provide digital solutions that work best with your business
  </div>
  <div class="mb-100" fxLayout="row" fxLayoutAlign="space-around">
    <div class="first-banner" fxFlex="85" fxLayout="row" fxLayout.lt-sm="column" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="47" fxLayoutAlign="center flex-start">
        <div class="heading f-25 mb-25 heading-non-tablet">
          We provide digital solutions that work best with your business
        </div>
        <div class="description mb-40">
          <!-- Hello there, looking for a highly creative team for your next project? you’re in a good place. <br> -->
          At Incodus Technologies we provide services from business model and
          value-oriented UX to technological implementation and scaling.
        </div>
        <button [routerLink]="'contact-us'" mat-button class="description-bold mb-20 contact-us">
          Contact us
        </button>
        <div inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="action($event)">
          <!-- <div *ngIf="downloadButtonService.isiOS || downloadButtonService.isNotAndroidNotiOS" class="store-btns app-store" (click)="goToStore(); "></div>
                    <div *ngIf="downloadButtonService.isAndroid || downloadButtonService.isNotAndroidNotiOS" class="store-btns play-store" (click)="goToStore(); "></div> -->
          <div class="clearfix"></div>
        </div>
      </div>
      <div fxFlex="45" class="image-container">
        <img class="image-hero-cardapp" [src]="'assets/images/landing.svg'" />
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="second-banner" fxLayout="column" fxFlex="80" fxLayoutAlign="center center">
      <div id="sHeading" class="heading mb-10" fxLayoutAlign="center">
        <!-- Not just another tech company -->
        Services
      </div>
      <div id="sSubHeading" class="description mb-20" fxLayoutAlign="center">
        <!-- practising perfectionism through excellence -->
        <!-- We make technology work for you
                We take businesses to where it has never  -->
        <!-- we are a company that cares about your business -->
      </div>

      <div class="non-desktop">
        <div class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
          <img class="image-home" src="../../../assets/images/strategy.svg" />
          <!-- <video id="myVideo" controls loop preload='auto'>
                        <source src="../../../assets/video/How to Spend Wisely-Part 2-Final.mp4" type="video/mp4">
                        Your browser does not support HTML5 video.
                      </video> -->
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Strategy</div>
            <div class="sub-description">
              Most of the products fail not because they are not competitive, but the top management lacks the vision
              and strategy to make an impact in the market.
              <div class="mb-20"></div>
              At Incodus we specialise in strategy formation and helping like minded people who have vision but not have
              the appropriate tools to implement the same.
              <div class="mb-20"></div>
            </div>
          </div>
        </div>

        <div class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column" fxLayout.lt-md="row-reverse" fxLayoutAlign="space-between center">
          <div class="video-cotainer">
            <img class="image-home" [src]="'assets/images/ui-ux.svg'" />
          </div>
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">UI/UX Design</div>
            <div class="sub-description">
              First impression lasts. That's why having a presentable website
              makes a good first impression on the client. <br />
              Incodus technologies has a rich experience in designing and
              navigating the flow of the website.
              <div class="mb-20"></div>
              The main focus of Incodus is the user presentation layer of an
              application, with additional functionality for managing events and
              for managing the state of the application flow. UIX is designed to
              create applications with page-based navigation, such as an online
              human resources application, rather than full-featured
              applications requiring advanced interaction, such as an integrated
              development environment (IDE).
            </div>
          </div>
        </div>

        <div class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
          <img class="image-home" [src]="'assets/images/front-end.svg'" />
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Web / App Development</div>
            <div class="sub-description">
              In this fast paced world, the only option that the businesses have
              is to digitize their business in order to make their presence
              noticeable.
              <div class="mb-20"></div>
              At Incodus we not only digitize your busniess but also provide you
              with our most talented resources who can build website that are
              competitive and leave a mark on the user.
            </div>
          </div>
        </div>

        <div class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column" fxLayout.lt-md="row-reverse" fxLayoutAlign="space-between center">
          <img class="image-home" [src]="'assets/images/content.svg'" />
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Content / Copywriting</div>
            <div class="sub-description">
              Words do matter, one of the most important aspect of the website should be the content.
              <div class="mb-20"></div>
              Even if you have a great visual website, but if the content does not reflect your vision/mission, it will
              hinder the success of your project /
            </div>
          </div>
        </div>

        <div class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
          <img class="image-home" [src]="'assets/images/digital-marketing.svg'" />
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Digital Marketing</div>
            <div class="sub-description">
              Digital marketing refers to advertising delivered through digital channels such as search engines,
              websites, social media, email, and mobile apps. Using these online media channels, digital marketing is
              the method by which companies endorse goods, services, and brands.
              <div class="mb-20"></div>
              We build, grow and help you scale by bringing the best digital marketing initiatives that aligns with your
              brand's mission.
            </div>
          </div>
        </div>
      </div>












      <div class="desktop">
        <div id="service1" class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="space-between center">
          <div fxFlex="50" class="service-image-container">
            <div [ngClass]="{ sticky: isSticky }">
              <img class="phone-image" [src]="'assets/images/strategy.svg'" [ngClass]="{
                  'visible-no-anim': currentService === 1,
                  'hidden-no-anim': currentService !== 1
                }" />
              <!-- <img class="phone-inside" [src]="'assets/images/service-3-inside.png'"
                                [ngClass]="{'visible' : currentService === 1, 'hidden' : currentService !== 1}"> -->
              <!-- <img class="service-3-card-image" [src]="'assets/images/service-3-card-image.png'"
                                    [ngClass]="{'visible' : currentService === 1, 'hidden' : currentService !== 1}"> -->
            </div>
          </div>

          <div id="s1Desc" class="service-text" [ngClass]="{ 'desc-sticky': isDescSticky }" fxFlex="45" fxFlex.sm="50"
            fxLayout="column">
            <div class="sub-heading mb-20 mt-55">Strategy</div>
            <div class="sub-description">
              <!-- We design and code thoughtful digital User experiences & beautiful
              websites and we would love to work with You. If you love our work
              and want to make beautiful and useful websites, let’s work
              together / Diversity and difference are our guiding principles. -->

              Most of the products fail not because they are not competitive, but the top management lacks the vision
              and strategy to make an impact in the market.
              <div class="mb-20"></div>
              At Incodus we specialise in strategy formation and helping like minded people who have vision but not have
              the appropriate tools to implement the same.
              <div class="mb-20"></div>
              <!-- We are a startup based in Ahmedabad, we have 2 years+ experience
              in website development and website designing. We are looking for
              talents whose mission and vision align with ours. The services
              provided by us are listed below and it’s not an exhaustive list. -->
            </div>
          </div>
        </div>

        <div id="service2" class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="space-between center">
          <div fxFlex="50" class="service-image-container">
            <div [ngClass]="{ sticky: isSticky }">
              <img class="phone-image" [src]="'assets/images/ui-ux.svg'" [ngClass]="{
                  'visible-no-anim': currentService === 2,
                  'hidden-no-anim': currentService !== 2
                }" />
              <!-- <img class="phone-inside" [src]="'assets/images/service-1-inside.png'"
                                [ngClass]="{'visible' : currentService === 2, 'hidden' : currentService !== 2}">
                            <div class="service-1-card" [ngClass]="{'sc-1-sticky': isSticky, 'visible' : currentService === 2, 'hidden' : currentService !== 2}">
                                <img class="service-1-card-image" [src]="'assets/images/service-1-card-image.png'"
                                [ngClass]="{'visible' : currentService === 2, 'hidden' : currentService !== 2}"> 
                            </div> -->
            </div>
          </div>
          <div id="s2Desc" fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">UI / UX Design</div>
            <div class="sub-description">
              First impression lasts. That's why having a presentable website
              makes a good first impression on the client. <br />
              Incodus technologies has a rich experience in designing and
              navigating the flow of the website.
              <div class="mb-20"></div>
              The main focus of Incodus is the user presentation layer of an
              application, with additional functionality for managing events and
              for managing the state of the application flow. UIX is designed to
              create applications with page-based navigation, such as an online
              human resources application, rather than full-featured
              applications requiring advanced interaction, such as an integrated
              development environment (IDE).

              <!-- <div class="mb-20"></div>
                          Awsm’s 100+ courses based on the <strong>National Standards for Financial Literacy</strong> framework, will help them understand money matters, avoid common pitfalls, and develop good financial habits. -->
            </div>
          </div>
        </div>

        <div id="service3" class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="space-between center">
          <div fxFlex="50" class="service-image-container">
            <div [ngClass]="{ sticky: isSticky }">
              <img class="phone-image" [src]="'assets/images/front-end.svg'" [ngClass]="{
                  'visible-no-anim': currentService === 3,
                  'hidden-no-anim': currentService !== 3
                }" />
              <!-- <img class="phone-inside" [src]="'assets/images/service-2-inside.png'"
                                [ngClass]="{'visible' : currentService === 3, 'hidden' : currentService !== 3}">
                            <div class="service-2-card" [ngClass]="{'sc-2-sticky': isSticky, 'visible': currentService === 3, 'hidden' : currentService !== 3}">
                                <img class="service-2-card-image" [src]="'assets/images/service-2-card-image.png'"
                                    [ngClass]="{'service2Inc' : currentService === 3, 'service2Dec' : currentService !== 3, 'visible' : currentService === 3, 'hidden' : currentService !== 3}">
                            </div> -->
            </div>
          </div>
          <div fxFlex="45" class="service-3-desc" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Web / App Development</div>
            <div class="sub-description">
              In this fast paced world, the only option that the businesses have
              is to digitize their business in order to make their presence
              noticeable.
              <div class="mb-20"></div>
              At Incodus we not only digitize your busniess but also provide you
              with our most talented resources who can build website that are
              competitive and leave a mark on the user.
            </div>
          </div>
        </div>

        <div id="service4" class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="space-between center">
          <div id="s4Image" fxFlex="50" class="service-image-container">
            <div [ngClass]="{ sticky: isSticky }">
              <img class="phone-image" [src]="'assets/images/content.svg'" [ngClass]="{
                  'visible-no-anim': currentService === 4,
                  'hidden-no-anim': currentService !== 4
                }" />
              <!-- <img class="phone-inside" [src]="'assets/images/service-4-inside.png'"
                                [ngClass]="{'visible' : currentService === 4, 'hidden' : currentService !== 4}">
                            <div id="s4Card" class="service-4-card" [ngClass]="{'sc-4-sticky': isSticky, 'visible' : currentService === 4, 'hidden' : currentService !== 4}">
                                <img class="service-4-card-image" [src]="'assets/images/service-4-card-image.png'"
                                    [ngClass]="{'visible' : currentService === 4, 'hidden' : currentService !== 4}">
                            </div> -->
            </div>
          </div>
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Content / Copywriting</div>
            <div class="sub-description">
              <div class="mb-20"></div>
              Words do matter, one of the most important aspect of the website
              should be the content.
              <div class="mb-20"></div>
              Even if you have a great visual website, but if the content does
              not reflect your vision/mission, it will hinder the success of
              your project.
            </div>
          </div>
        </div>

        <div id="service5" class="mb-80 services" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="space-between center">
          <div id="s5Image" fxFlex="50" class="service-image-container">
            <div [ngClass]="{ sticky: isSticky }">
              <img class="phone-image" [src]="'assets/images/digital-marketing.svg'" [ngClass]="{
                  'visible-no-anim': currentService === 5,
                  'hidden-no-anim': currentService !== 5
                }" />
              <!-- <img class="phone-inside" [src]="'assets/images/service-4-inside.png'"
                                [ngClass]="{'visible' : currentService === 4, 'hidden' : currentService !== 4}">
                            <div id="s4Card" class="service-4-card" [ngClass]="{'sc-4-sticky': isSticky, 'visible' : currentService === 4, 'hidden' : currentService !== 4}">
                                <img class="service-4-card-image" [src]="'assets/images/service-4-card-image.png'"
                                    [ngClass]="{'visible' : currentService === 4, 'hidden' : currentService !== 4}">
                            </div> -->
            </div>
          </div>
          <div fxFlex="45" fxFlex.sm="50" fxLayout="column">
            <div class="sub-heading mb-20">Digital Marketing</div>
            <div class="sub-description">
              Digital marketing refers to advertising delivered through digital
              channels such as search engines, websites, social media, email,
              and mobile apps. Using these online media channels, digital
              marketing is the method by which companies endorse goods,
              services, and brands.
              <div class="mb-20"></div>
              We build, grow and help you scale by bringing the best digital marketing initiatives that aligns with your
              brand's mission.
            </div>
          </div>
        </div>
      </div>

      <div class="heading cards-heading text-center mb-10" fxLayoutAlign="center">
        Build interactive websites without inflating your budget
      </div>
      <div class="description large text-center mb-70" fxLayoutAlign="center">
        Empowering businesses to focus on their project and let us handle the
        development of websites.
      </div>

      <div class="mb-100 cards-container" fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap="grid">
        <div class="home-card independence-realtimereports mb-50">
          <div class="image-container" fxLayoutAlign="center center">
            <object type="image/svg+xml" data="assets/images/home-available.svg" class="home-card-vector-available">
              <img [src]="'assets/images/home-available.svg'" />
            </object>
          </div>
          <div class="body">
            <div class="title mb-10">Available 24*7</div>
            <div class="description">
              We are always available and are just one call away
            </div>
          </div>
        </div>

        <div class="home-card independence-cardonoff mb-50">
          <div class="image-container" fxLayoutAlign="center center">
            <object type="image/svg+xml" data="assets/images/home-saving.svg" class="home-card-vector-budget">
              <img [src]="'assets/images/home-saving.svg'" />
            </object>
          </div>
          <div class="body">
            <div class="title mb-10">Budget friendly</div>
            <div class="description">
              Your budget friendly neighbourhood IT company
            </div>
          </div>
        </div>

        <div class="home-card independence-atmlimits mb-50">
          <div class="image-container" fxLayoutAlign="center center">
            <object type="image/svg+xml" data="assets/images/home-flawless.svg" class="home-card-vector-flawless">
              <img class="home-flawless" [src]="'assets/images/home-flawless.svg'" />
            </object>
          </div>
          <div class="body">
            <div class="title mb-10">Flawless timely execution</div>
            <div class="description">
              Our experience in project delivery ensures that we quickly grasp
              the issues and solve them in
            </div>
          </div>
        </div>

        <div class="home-card independence-spendingboudaries mb-50">
          <div class="image-container" fxLayoutAlign="center center">
            <object type="image/svg+xml" data="assets/images/home-design.svg" class="home-card-vector-available">
              <img [src]="'assets/images/home-design.svg'" />
            </object>
          </div>
          <div class="body">
            <div class="title mb-10">We build designs that communicate</div>
            <div class="description">
              Our mission is to create materials that influence your audience
              and further your goals,.
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="heading text-center mb-10" fxLayoutAlign="center">
                Security and Privacy You Can Trust
            </div>
            <div class="description text-center mb-70" fxLayoutAlign="center">
                From FDIC insurance to fraud protection
            </div>

            <div class="mb-40 width-100 bl-cards-container" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="grid">
                <div class="border-less-card mb-40">
                    <img class="mb-15" [src]="'assets/images/7-security-FDIC-icon.svg'">
                    <div class="title">
                        FDIC Insured
                    </div>
                    <div class="description">
                        FDIC-insured up to $250,000 through Evolve Bank & Trust.
                    </div>
                </div>

                <div class="border-less-card mb-40">
                    <img class="mb-15" [src]="'assets/images/7-security-fraudprotection-icon.svg'">
                    <div class="title">
                        Protected Against Fraud
                    </div>
                    <div class="description">
                        MasterCard Zero Liability Protection against unauthorized transactions in stores, online, and via ATM.
                    </div>
                </div>

                <div class="border-less-card mb-40">
                    <img class="mb-15" [src]="'assets/images/7-security-encryption-icon.svg'">
                    <div class="title">
                        State-Of-The-Art-Encrypted
                    </div>
                    <div class="description">
                        Secure communications between your phone and our servers thanks to state-of-the-art encryption.
                    </div>
                </div>
            </div> -->

      <div class="about-us-container">

        <h1 class="about-us">About us</h1>

        <p class="our-team-header-description">
          We are a startup based in Ahmedabad. We have 4 years+ experience in website development and website designing.
          Our main aim is to provide economical and feasible solutions to the client.
          Incodus is your single access point for all your digital solutions.
        </p>


      </div>

      <!-- <div class="our-team ">
        <h1 class="our-team-header">our leaders</h1>

        <p class="our-team-header-description">
          We are on a mission to make a meaningful contribution to the startup
          ecosystem - one day at a time.
        </p>

        <div class="card-container" fxLayout="row" fxLayout.lt-xs="column" fxLayoutAlign="space-around center">
          <mat-card *ngFor="let leader of leaderCardArray" class="our-team-card">
            <section fxLayout="column" fxLayoutAlign="space-between center" class="our-team-card-founder-info">
              <img [src]="leader.img" alt="" />
              <div>
                <h4>{{ leader.personName }}</h4>
                <p>co-founder</p>
              </div>
            </section>

            <section fxLayout="column" fxLayoutAlign="center center" class="our-team-card-content">
              <h4>{{ leader.personName }}</h4>
              <p class="our-team-card-content-person-desc">
                {{ leader.personDesc }}
              </p>
              <img src="../../../assets/icons/linkedin-brands-copy.svg" alt="" />
            </section>
          </mat-card>
        </div>
      </div> -->
    </div>
  </div>
</div>