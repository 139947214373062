<div fxLayout="column" fxLayoutAlign="center center">
    <div class="heading" fxLayout="column" fxLayoutAlign="center center">
        Why Incodus
    </div>

    <div class="width-85 mb-120 container" fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="space-between">
        <div fxFlex="47" fxLayout="column" fxLayoutAlign="space-around flex-start">
            <div class="description" style="margin-top: -80px;">
                We are a startup based in Ahmedabad, we have 4 years+ experience in website development and website
                designing. We are looking for talents whose mission and vision align with ours.
                <div class="mb-20"></div>
                We design and code thoughtful digital User experiences & creative websites and we would love to work
                with You. If you love our work and want to make creative and useful websites, let’s work together /
                Diversity and difference are our guiding principles. Our approach is tailored and unique to each client
                / Hello there, looking for a highly creative team for your next project, you’re in a good place.
            </div>
        </div>
        <div fxFlex="47">
            <img class="why-us-image why-us-1" [src]="'assets/images/home-passion.svg'">
        </div>
    </div>

    <div class="width-85 mb-120 container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
        <div fxFlex="47">
            <img class="why-us-image why-us-2" [src]="'assets/images/why-creative.svg'">
        </div>
        <div fxFlex="47" fxLayout="column" fxLayoutAlign="center flex-start">
            <div class="sub-heading mb-25">
                We have creative ideas and designs that communicate
            </div>
            <div class="description">
                We have a unique understanding of marketing logic fuelled by intuitive creative thinking. Our
                perspective allows us to create ideas that resonate with your audience to make a lasting impact with
                your message. Our mission is to create materials that influence your audience and further your goals,
                not just decorate a page. We work with companies to understand their exact requirements so that we can
                provide a tailored solution for their users.
            </div>
        </div>
    </div>

    <div class="width-85 mb-120 container" fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="space-between">
        <div fxFlex="47" fxLayout="column" fxLayoutAlign="center flex-start">
            <div class="sub-heading mb-25">
                Flawless timely execution and available 24*7
            </div>
            <div class="description">
                Our experience in project delivery ensures that we quickly grasp the issues, sweat the details and
                produce a website that exceeds your expectations and instils delight in your users in a timely manner.
                Our team is 24*7 available at your service. This is a much needed aspect for the client, as many times
                the changes that are suggested/needed by the client needs immediate attention and implementation as the
                client's customer is facing some constraints, but due to unavailability of proper service, uncertainty kicks
                in.
            </div>
        </div>
        <div fxFlex="47">
            <img class="why-us-image why-us-3" [src]="'assets/images/why-flawless.svg'">
        </div>
    </div>
</div>