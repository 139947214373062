<div class="contact-container" fxLayout="column">
    <h2 class="contact-header heading mb-90 mt-80">Contact us</h2>
    <div fxLayoutAlign="space-between center" fxLayout.lt-md="column-reverse">

        <section fxFlex="45" fxFlex.lt-md="90" fxLayout="column" class="contact-details">
            <p>Do you want to talk about your project? Feel free & contact us.</p>

            <form [formGroup]="contactForm" fxLayout="column">
                <mat-form-field class="contact-container-form">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                    <mat-error
                    *ngIf="contactForm.controls['name'].hasError('required') && contactForm.controls['name'].touched">
                    Name is required</mat-error>
                    <mat-error
                    *ngIf="contactForm.controls['name'].hasError('pattern') && contactForm.controls['name'].touched">
                    Please enter a valid name!</mat-error>
                </mat-form-field>
    
                <mat-form-field class="contact-container-form">
                    <mat-label>Number</mat-label>
                    <input matInput formControlName="number">
                    <mat-error
                    *ngIf="contactForm.controls['number'].hasError('required') && contactForm.controls['number'].touched">
                    Number is required</mat-error>
                    <mat-error
                    *ngIf="contactForm.controls['number'].hasError('pattern') && contactForm.controls['number'].touched">
                    Please enter a valid number!</mat-error>
                </mat-form-field>
    
                <mat-form-field class="contact-container-form">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-error
                    *ngIf="contactForm.controls['email'].hasError('required') && contactForm.controls['email'].touched">
                    Email is required</mat-error>
                    <mat-error
                    *ngIf="contactForm.controls['email'].hasError('pattern') && contactForm.controls['email'].touched">
                    Please enter a valid email!</mat-error>
                </mat-form-field>
    
                <mat-form-field class="contact-container-form">
                    <mat-label>Query</mat-label>
                    <textarea matInput formControlName="query"></textarea>
                    <mat-error
                    *ngIf="contactForm.controls['query'].hasError('required') && contactForm.controls['query'].touched">
                    Query is required</mat-error>
                </mat-form-field>
            </form>

            <div class="button-container" fxLayout="row" fxLayoutAlign="space-around centre">

                <button mat-button fxFlex="30" class="reset-btn" (click)="onReset()">
                    Reset
                </button>
                
                <button mat-button fxFlex="30" class="submit-btn" (click)="onSubmit()">
                    Submit
                </button>
            </div>


        </section>
        <img src="../../../assets/images/contact-us.svg" alt="" fxFlex="45" width="100%" fxFlex.lt-md="80">

    </div>
</div>